@import-normalize;

html, body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;

}

#root{
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/quicksand/Quicksand-Medium.ttf') format('truetype');
}


/*ANIMATIONS*/


.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/*slide-from-left*/

.slide-left-enter{
  opacity: 0;
  transform: translateX(-10000px);
}
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.slide-left-exit {
  opacity: 1;
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-10000px);
  transition: opacity 300ms, transform 300ms;
}
