.example-appear {
    opacity: 0.01;
}

.example-appear.example-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.slick-prev:before, .slick-next:before{
    color: black;
}

.MuiExpansionPanel-root:before{
    content: none !important;
}
