.textLink{
    align-self: flex-start;
    margin-bottom: 20px;
}
.login{
    margin-top: 95px;
    margin-bottom: 35px;
}
.register{
    margin-top: 30px;
    margin-bottom: 25px;
}

.logo{
    margin-top: 30px;
}
.wrapper{
    padding-top: 30px;
}